import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";

const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/home"),
    }, 
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login"),
      }, 
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/profile"),
    },
    {
        path: "/play",
        name: "play",
        component: () => import("@/views/play"),
      }, 
  ];
  
  const router = createRouter({
    history: createWebHistory(),
    routes: routes,
  });

  
/**
 * 前置守卫
 */
router.beforeEach(async (to, from, next) => {
    NProgress.start();
  
    next();
  });
  
  /**
   * 后置守卫
   */
  router.afterEach(() => {
    NProgress.done();
  });

export default router;