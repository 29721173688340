/*
 * @Description: 网络请求
 * @Author: 琢磨先生
 * @Date: 2024-07-11 20:58:10
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-24 17:57:56
 */
import axios from "axios";
import router from "@/install/router";
import cfg from "@/config/config.js";

let config = {
  baseURL: cfg.HOST,
  timeout: 600000, //
};

const _axios = axios.create(config);

//请求开始时链接
_axios.interceptors.request.use(
  (config) => {
    //获取token
    const token = sessionStorage.getItem(cfg.TOKEN);
    if (token) {
      config.headers.token = token;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  (response) => {
    if (response.config.responseType == "blob") {
      return response.data;
    }
    console.log(response.config.url, response.data);
    //未登录或已过期
    if (response.data.code == 40101) {
      router.replace({
        name: "login",
      });
    } else if (response.data.code != 200) {
    }

    return response.data;
  },
  (error) => {
    // var message = `网络请求错误，状态码：${error.response.status}`;
    // switch (error.response.status) {
    //   case 403:
    //     message = "没有权限，拒绝访问";
    //     break;
    // }
    return Promise.reject(error);
  }
);

export default _axios;
