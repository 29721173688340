import { createApp } from 'vue'
import App from './App.vue'
import Vant from 'vant';
import axios from '@/http/axios_init.js';
import router from './install/router.js';
import config from "@/config/config";
import 'vant/lib/index.css';
import "nprogress/nprogress.css";
import "@/assets/app.css";



const app = createApp(App)

app.use(Vant);

const install = (app) => {
    app.config.globalProperties.$http = axios;
    app.config.globalProperties.$cfg = config;
  };
  
app.use(router);
app.use(install);

app.mount("#app");