export default {

    /**
   * api请求host地址
   */
  HOST:
  process.env.NODE_ENV === "development"
    ? "https://api.ts.itxiangtian.cn"
    : "https://api.ts.itxiangtian.cn",
  /**
   * 本地缓存token名称
   */
  TOKEN: "token",
}